import React from 'react';

function MainIndex() {
	return (
	    <ul>
		<li> <a href="/loader">Loader (Brian)</a> </li>
		<li> <a href="/dev">Development (Marcos)</a> </li>
	    </ul>
	);
};

export default MainIndex;
